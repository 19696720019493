import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import Leaderboard from '../../common/leaderboard';

export default function Participants(props:{
  participantsData:ILearningGamePlayerRank[]
  analyticsData:ILearningGamePlayerRank[]
  download:()=>void
  journeyDetails:{
    name: string;
    divisionId: string;
  }
}) {

  const columns: IDatableColumn[] = [
    {
      name: 'Learner Id',
      key: 'learnerId',
    },
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: 'Email',
      key: 'email',
    },
    {
      name: 'Highest Score',
      key: 'highestScore',
      type: 'number',
    },
    {
      name: 'Last Completed Date',
      key: 'completedAt',
      type: 'string',
      formatter: '',
    },
  ];


  return (
    <Leaderboard
      data={props.participantsData.sort((a, b) => b.highestScore - a.highestScore)}
      columns={columns}
      showIndex={true}
      type="new"
      total={props.participantsData.length}
      name={props.journeyDetails?.name}
      downloadable={true}
      excelFileName={`${props.journeyDetails?.name}  (Participants data) `}
      customDownload={props.download}
    />
  );
}

import { Tabs, Tab, Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ITabPanelProps } from '../../interface/tab-panel.interface';
import Participants from './Participants';
import Analytics from './Analytics';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { Column, Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {
  fetchJourney,
  getAnalytics,
  getGenieLearningGamePlayerLeaderboard,
} from '../../common/api/apicall';
import { months } from '../../common/constants';
import { useParams } from 'react-router-dom';
import { IDatableColumn } from '../../common/leaderboard/column.interface';

// const useStyles = makeStyles({
// });

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function GenieStaging() {
  const [value, setValue] = useState(0);
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const [analyticsData, setAnalyticsData] = useState<ILearningGamePlayerRank[]>(
    [],
  );
  const { id: journeyId } = useParams();
  const [journeyDetails, setJourneyDetails] = useState<{
    name: string;
    divisionId: string;
    gameFormat: string;
    createdAt: string;
  }>();

  const newColumns =
    ((journeyDetails as any)?.gameFormat === 'JumblesGame'
      ? (journeyDetails as any)?.jumbles
      : (journeyDetails as any)?.questions
    )?.map((item: any, index: any) => {
      return {
        header: `Q${index + 1}`,
        key: `q-${item._id}`,
        width: 50,
      };
    }) || [];

  const worksheetColumns = [
    { header: 'Learner Id', key: 'learnerId', width: 40 } as Column,
    { header: 'Name', key: 'name', width: 40 } as Column,
    { header: 'Email', key: 'email', width: 40 } as Column,
    {
      header: 'Highest Score',
      key: 'highestScore',
      width: 30,
      alignment: { horizontal: 'center' },
    } as Column,
    {
      header: 'Accuracy (Percentage)',
      key: 'accuracy',
      width: 30,
    } as Column,
    { header: 'Last Completed Date', key: 'completedAt', width: 30 } as Column,
    ...newColumns,
  ];

  useEffect(() => {
    async function getGenieJourney() {
      try {
        const journey = await fetchJourney(journeyId as string, false);
        let isJumbles = journey.gameFormat === 'JumblesGame';
        setJourneyDetails(journey);

        getAnalytics(journey.divisionId, false, isJumbles).then((analytics) => {
          console.log({ analytics });
          // Process the analytics data
          const processedData = analytics.map(
            (
              question: {
                correctOnFirstAttempt: number;
                totalPlayers: number;
                questionText: any;
                jumbleHint: any;
              },
              index: number,
            ) => {
              const accuracy = (
                (question.correctOnFirstAttempt / question.totalPlayers) *
                100
              ).toFixed(2);

              return {
                number: index + 1,
                question: isJumbles
                  ? question.jumbleHint
                  : question.questionText,
                accuracy: question.totalPlayers ? `${accuracy}%` : '',
              };
            },
          );

          setAnalyticsData(processedData);
        });

        getGenieLearningGamePlayerLeaderboard(journey.divisionId, false, {
          includeNonPostive: true,
        })
          .then((res: any[]) => {
            const filteredPlayers: any[] = [];
            res.forEach((player, index) => {
              player['name'] =
                (player['firstName'] || '') +
                ' ' +
                (player['lastName'] || '') +
                (player['lastname'] || '');
              player['highestScore'] = player['lastHighestScoreHistory'];
              player.analytics[isJumbles ? 'jumbles' : 'questions'].map(
                (item: any) => {
                  // 1s *100/ (0s +1s)
                  player[`q-${isJumbles ? item.jumbleId : item.questionId}`] =
                    item.attempt === 0 ? 1 : 0;
                },
              );

              const numberOfOnes = player.analytics[
                isJumbles ? 'jumbles' : 'questions'
              ].filter((item: any) => item.attempt === 0).length;
              const numberOfOnesOrZeros =
                player.analytics[isJumbles ? 'jumbles' : 'questions'].length;
              // console.log({numberOfOnes , numberOfOnesOrZeros} , player.analytics.questions)

              const calculatedAccuracy =
                (numberOfOnes * 100) / numberOfOnesOrZeros;
              player['accuracy'] = isNaN(calculatedAccuracy)
                ? ''
                : calculatedAccuracy.toFixed(2);

              // console.log({numberOfOnesOrZeros , player})
              if (player.extraDetail) {
                player['employeeid'] = player.extraDetail['employeeid'];
              }

              if (player['email']?.trim()?.toLowerCase() === 'test@test.com') {
                player['email'] = '';
              }

              if (player['completedAt']) {
                const date = new Date(player['completedAt']);
                player['completedAt'] = !player['gameOver']
                ? ``
                :  date.toLocaleDateString('en-US', {
                  day: 'numeric',
                  // weekday: 'short',
                  year: 'numeric',
                  month: 'long',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                });
                // player['completedAt'] = !player['gameOver']
                //   ? ``
                //   : `${date.getDate()} ${
                //       months[date.getMonth()]
                //     }, ${date.getFullYear()}`;
              }
              //     if (player.extraDetail) {
              filteredPlayers.push(player);
              //  }
            });
            setData(filteredPlayers);
          })
          .catch((error) => console.log('error: ', error));
      } catch (error) {}
    }
    getGenieJourney();
  }, []);

  function download() {
    const workbook = new Workbook();
    const participantSheet = workbook.addWorksheet('Participant data');
    participantSheet.columns = worksheetColumns;
    // worksheet.columns = worksheetColumns;
    participantSheet.addRows(data);

    const analyticsSheet = workbook.addWorksheet('Question level analytics');
    analyticsSheet.columns = [
      { header: 'Question no', key: 'number', width: 50 } as Column,
      { header: 'Question text', key: 'question', width: 50 } as Column,
      { header: 'Accuracy', key: 'accuracy', width: 50 } as Column,
    ];
    analyticsSheet.addRows(analyticsData);

    const legendSheet = workbook.addWorksheet('Legend');
    legendSheet.columns = [
      { header: 'Journey Name', key: 'name', width: 50 } as Column,
      { header: 'Creation Date', key: 'date', width: 50 } as Column,
      { header: 'Game Format', key: 'format', width: 50 } as Column,
    ];
    let date = new Date(journeyDetails?.createdAt as string);
    legendSheet.addRows([
      {
        name: journeyDetails?.name,
        format: journeyDetails?.gameFormat,
        date: `${date.getDate()} ${
          months[date.getMonth()]
        }, ${date.getFullYear()}`,
      },
    ]);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(
        blob,
        `${journeyDetails?.name}  (Participants data) ` + '.xlsx',
      );
    });
  }

  const handleChange = (event: any, newValue: number) => {
    console.log('event: ', event, 'newValue: ', newValue);

    setValue(newValue);
  };

  // const classes = useStyles();

  return (
    <>
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event, i) => handleChange(event, i)}
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Participants" style={{ fontSize: 13 }} {...a11yProps(0)} />
        <Tab label="Analytics" style={{ fontSize: 13 }} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Participants
          participantsData={data}
          analyticsData={analyticsData}
          download={download}
          journeyDetails={journeyDetails!}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Analytics
          participantsData={data}
          analyticsData={analyticsData}
          download={download}
          journeyDetails={journeyDetails!}
        />
      </TabPanel>
    </>
  );
}

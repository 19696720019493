import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import Leaderboard from '../../common/leaderboard';

export default function Analytics(props: {
  participantsData: ILearningGamePlayerRank[];
  analyticsData: ILearningGamePlayerRank[];
  download: () => void;
  journeyDetails: {
    name: string;
    divisionId: string;
  };
}) {
  const columns: IDatableColumn[] = [
    {
      name: 'Question no',
      key: 'number',
    },
    {
      name: 'Question text',
      key: 'question',
      type: 'string',
    },
    {
      name: 'Accuracy',
      key: 'accuracy',
    },
  ];

  return (
    <Leaderboard
      data={props.analyticsData}
      columns={columns}
      showIndex={false}
      type="new"
      total={props.analyticsData.length}
      name={props.journeyDetails?.name}
      downloadable={true}
      excelFileName={`${props.journeyDetails?.name} (Question-wise analytics) `}
      customDownload={props.download}
    />
  );
}
